console.log("bundle is connected");

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swiper from 'swiper/';
import Navigation from 'swiper/modules/navigation.min.mjs';

window.Swiper = Swiper;
window.Navigation = Navigation;

import Rellax from 'rellax';
window.Rellax = Rellax;

